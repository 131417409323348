@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


*{
    font-family: "Roboto", sans-serif; 
}

html {
    scroll-behavior: smooth;
  }

header{
    z-index: 1001 !important;
}

.BGShade{
    background:  #FFFFFF;
}

.cardCont div{
    justify-content: unset !important;
}

.cardChild{
    margin: 10px;
}

.lg-react-element img{
    max-width: 100%;
    border-radius: 10px;
    transition: transform 0.2ss;
    border:1px solid black; 

  }

  .lg-react-element img:hover{
    filter: opacity(.9);
    transform: scale(1.01);
    cursor: pointer;
    width:25
  }

  .yarl__container {
    background: rgba(0, 0, 0, 0.6) !important;
  }

   .slick-prev, .slick-next { 
    border-radius: 50% !important;
    background-color: #1f0e0e !important;
    font-size: large;
   }

  
   @media screen and (max-width: 1023px) {
    .slick-next,
    .slick-prev {
      display: none !important; /* Hide arrows on screens below 763px width */
    }
  }

  .react-photo-album--row img{
    border: 2px solid #4CAF4F;
    border-radius: 10px;
  }

  .VideoCarousel{
    display: flex !important;
    flex-wrap: wrap !important;
  }
.FooterImg img{
  width:80px !important;
  height:auto !important;
}

@media screen and (max-width: 650px) {
  .VideoCarousel{
    align-items: center !important;
    flex-direction: column !important;
  }
  .VideoCarousel .SingleV{
    width: 80% !important;
  }
}



@media screen and (max-width: 55) {
  .flowbite-card div{
    padding:'1rem' !important;
  }
}

.BgHomeImg {
  background: linear-gradient(45deg, rgb(0,152,254,0.6),  rgba(25, 118, 180, 0.5)),
              url( 'https://images.unsplash.com/photo-1537495329792-41ae41ad3bf0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGJvb2tzfGVufDB8MXwwfHx8MA%3D%3D' );
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  opacity: 1.6;
  min-height: 40%;
  background-attachment: fixed; /* Add this line to fix the background image */
}

.cards:hover p{
  opacity: 100 !important;
}


.NAV{
  cursor: pointer;
  position:relative;
  
}

.NAV::after{
  content: "";
  position: absolute;
  bottom: -5px;
  height: 4px;
  width: 100%;
  left: 0;
  background-color: #29166F;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0);
}

.NAV:hover::after{
  transform: scaleX(1);
}


.flip-container {
  perspective: 1000px;
}

.flip-box {
  width: 100%;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform-origin: center;
}
.custom-col-2 {
  padding-right: 40px !important;
}

.flip-box:hover {
  transform: rotateY(180deg);
}
.selected {
  background-color: #29166F !important ;
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 35px;
  transition: 0.2s ease-in-out;
}

.front {
  background-color: #29166F;
}

.back {
  transform: rotateY(180deg);
  background-color: #0098fe;
}

  .active{
    background-color: #29166F;
  }

  .CardHeader{
    padding: 4px !important;
  }

  .termsAndCondition li{
    font-size: 1rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-bottom: 8px;
  }

  .termsAndCondition{
    margin: 10px 25px;
    list-style-type: decimal;
  }
  .Testi-Carousel .slick-track{
    display: inline-flex;
    gap: 13px;
    justify-content:center;
    align-items:center
  }

  .loginModel div{
    padding:15px !important;
  }

  @media screen and (max-width: 1022px) {
    .adminModelBody{
      background-image: linear-gradient(45deg, rgb(0,152,254,0.6),  rgba(25, 118, 180, 0.5)),url('./Assets/admin_img.jpg') !important;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
}


    

